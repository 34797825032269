<template>
  <div>
    <el-image class="banner" :src="headBanner" :alt="$t('siffa')"></el-image>
    <div class="content_area container">
      <div class="header" v-loading="loading">
        <div class="companyLogo">
          <el-image
            :src="dataInfo.company_logo ? dataInfo.company_logo : DEFAULT_LOGO"
            :alt="$t('siffa')"
            style="width: 96px; height: 96px"
            :fit="'scale-down'"
          >
          </el-image>
        </div>
        <div class="left">
          <div class="companyName">
            {{ dataInfo.name_en | priorFormat(dataInfo.name_zh, LOCALE) }}
            <el-popover
              placement="top-start"
              :title="$t('membershipTime')"
              width="200"
              trigger="hover"
              :content="dataInfo.vip_end_time | secondFormat('LL')"
            >
              <span
                v-show="dataInfo.genre_ids && dataInfo.genre_ids.length > 0"
                class="pointer"
                slot="reference"
              >
                <!-- <el-image
                  style="width: 50px; height: 16px; margin-left: 8px"
                  :src="memberUrl"
                  fit="scale-down"
                  :alt="$t('siffa')"
                ></el-image> -->
              </span>
            </el-popover>
          </div>
          <span class="companyCredit" v-if="dataInfo.company_credit">{{
            dataInfo.company_credit | creditFilter(LOCALE)
          }}</span>
          <div class="companyIntroduce">
            <div class="companyAdress">
              <span class="titleFont">{{ $t("region") }}</span>
              <span>{{
                dataInfo.region_en | priorFormat(dataInfo.region_zh, LOCALE)
              }}</span>
            </div>
            <div class="companyIndustry">
              <span class="titleFont">{{ $t("companyIndustry") }}</span>
              <span>{{
                dataInfo.industry | industryFormat(LOCALE) | textFormat
              }}</span>
            </div>
            <div class="companyTypes">
              <span class="titleFont">{{ $t("unitType") }}</span>
              <span
                v-for="(item, index) in dataInfo.genre_ids"
                :key="index"
                class="companyType"
              >
                {{ item | unitTypeFormat(LOCALE) | textFormat }}
              </span>
              <span v-if="dataInfo.genre_ids && dataInfo.genre_ids.length === 0"
                >-</span
              >
            </div>
          </div>
          <div class="advantage">
            <span class="titleFont">{{ $t("companyGoodBusiness") }}</span>
            <span
              v-if="
                dataInfo.business_classification_list &&
                dataInfo.business_classification_list.length > 0
              "
            >
              <span
                class="label"
                v-for="(item, index) in dataInfo.business_classification_list"
                :key="index"
              >
                {{ item.desc_en | priorFormat(item.desc_zh, LOCALE) }}
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="right">
          <span
            class="collectBtn btn"
            @click="handleCollect(dataInfo.siffa_collection_id)"
            ><img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/heart.png"
              :alt="$t('siffa')"
              style="margin-right: 4px"
            />
            {{
              dataInfo.siffa_collection_id === 0
                ? this.$t("collectionCompany")
                : this.$t("cancelCollection")
            }}</span
          >
          <span v-if="dataInfo.website" class="goWebBtn btn">
            <a
              v-if="dataInfo.website.indexOf('http') != -1"
              :href="dataInfo.website"
              target="_blank"
              rel="nofollow"
              ><i class="el-icon-s-promotion" style="margin-right: 4px"></i
              >{{ $t("visitWebsite") }}</a
            >
            <a
              v-else
              :href="'http://' + dataInfo.website"
              target="_blank"
              rel="nofollow"
              ><i class="el-icon-s-promotion" style="margin-right: 4px"></i
              >{{ $t("visitWebsite") }}</a
            >
          </span>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <companyBrief :company_id="company_id" />
          <companyAuthentication :company_id="company_id" />
          <companyBusiness :company_id="company_id" />
          <!-- 企业评价 -->
          <comment @initSuccess="initSuccess(5)" />
        </div>
        <div class="right">
          <companyVideo :company_id="company_id"></companyVideo>
          <!-- 企业成员 -->
          <div class="companyMember">
            <companyMember :company_id="company_id"></companyMember>
          </div>
        </div>
      </div>
    </div>
    <cardIsDialog></cardIsDialog>
  </div>
</template>

<script>
import comment from "@/baseComponents/baseBooth/components/comment";
import companyBrief from "~hph/components/company/detail/companyBrief";
import companyAuthentication from "~hph/components/company/detail/companyAuthentication";
import companyBusiness from "~hph/components/company/detail/companyBusiness";
import companyVideo from "~hph/components/company/detail/companyVideo";
import companyMember from "~hph/components/company/detail/companyMember";
import cardIsDialog from "@/baseComponents/card/cardIsDialog";
import { priorFormat } from "@/basePlugins/filters";

import { _decode } from "@/basePlugins/base64";
export default {
  metaInfo() {
    let name = priorFormat(
      this.dataInfo.name_en,
      this.dataInfo.name_zh,
      this.$store.state.baseStore.locale
    );
    return {
      title:
        name +
        "公司简介/联系方式_企业名录_上海市国际货运代理行业协会官网（SIFFA）",
      meta: [
        {
          name: "keyWords",
          content: name + "公司简介," + name + "联系方式",
        },
        {
          name: "description",
          content:
            name +
            "已入驻成为上海市国际货运代理行业协会 （SIFFA）荣誉会员，用户可在上海市国际货运代理行业协会（SIFFA）官网查看 " +
            name +
            "相关信息，包括公司简介、业务信息、企业认证、联系方式等，欢迎登陆。",
        },
      ],
    };
  },
  name: "companyDetail",
  components: {
    companyBrief,
    companyAuthentication,
    companyBusiness,
    companyVideo,
    companyMember,
    comment,
    cardIsDialog,
  },
  data() {
    return {
      initCount: 0,
      dataInfo: {},
      defaultLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
      loading: false,
      company_id: "",
      meeting_id: "",
      headBanner:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/img_banner.png",
      memberUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/logo.png",
    };
  },
  filters: {
    creditFilter(val, locale) {
      let str = "-";
      switch (val) {
        case 1:
          str = locale == "en" ? "Credit-AAA" : "信用评级AAA";
          break;
        case 2:
          str = locale == "en" ? "Credit-AA" : "信用评级AA";
          break;
        case 3:
          str = locale == "en" ? "Credit-A" : "信用评级A";
          break;
      }
      return str;
    },
  },
  created() {
    if (this.$route.query.parameter) {
      this.company_id = this._decode(this.$route.query.parameter).company_id;
    }
    this.getDataInfo();
  },
  methods: {
    initSuccess(e) {
      this.initCount++;
      if (this.initCount == this.componentsCount) {
        this.loading = false;
      }
    },
    async getDataInfo() {
      let params = {
        company_id: this.company_id,
        user_id: this.USER_ID,
        source: this.PJSource,
      };
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "API_company/getCompanyHeadInfo",
          params
        );
        this.loading = false;
        if (res.success) {
          res.data.end_time = "";
          res.data.business_classification_list = this.handleData(
            res.data.business_classification
          );
          if (res.data.genre_ids.length > 1) {
            res.data.genre_ids = res.data.genre_ids.filter((ee) => ee != "1");
          }
          this.dataInfo = Object.assign({}, res.data);
        }
      } catch (e) {}
    },
    async handleCollect(value) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      let params = {};
      if (value == 0) {
        // 去收藏
        params.user_id = this.USER_ID;
        params.related_id = this.company_id;
        params.collection_type = 1;
        params.source = this.PJSource;
      } else {
        //取消收藏
        params.deleted = 1;
        params.siffa_collection_id = value;
      }
      let res = await this.$store.dispatch(
        "API_company/favoritesCompany",
        params
      );
      if (res.success) {
        this.$osTip();
        this.getDataInfo();
      }
    },
    handleGoWeb() {
      let _url;
      if (this.dataInfo.website.indexOf("http") != -1) {
        _url = this.dataInfo.website;
      } else {
        _url = `http://${this.dataInfo.website}`;
      }
      window.open(_url);
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
  },
};
</script>

<style scoped lang="less">
.banner {
  width: 100%;
  height: 170px;
}
.container {
  .header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 16px 0 34px 0;
    .companyLogo {
      position: absolute;
      top: -30px;
    }
    .left {
      width: 904px;
      padding-left: 121px;
      .companyName {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 11px;
      }
      .companyCredit {
        padding: 3px 14px;
        background: #fff0f0;
        color: #ff5155;
        font-size: 12px;
        font-weight: 400;
      }
      .companyIntroduce {
        display: flex;
        margin-top: 20px;
        .companyAdress {
          width: 30%;
        }
        .companyIndustry {
          width: 30%;
        }
        .companyTypes {
          width: 40%;
          .companyType {
            margin-left: 2px;
          }
        }
      }
      .advantage {
        margin-top: 16px;
        .label {
          padding: 2px 16px;
          border-radius: 2px;
          border: 1px solid #d9d9d9;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          font-size: 12px;
          margin-right: 12px;
          background: #f3f3f3;
          display: inline-block;
          margin-bottom: 4px;
        }
      }
      .titleFont {
        color: #333333;
        font-size: 14px;
        font-weight: 800;
        margin-right: 6px;
      }
    }
    .right {
      width: 270px;
      display: flex;
      .btn {
        width: 118px;
        height: 32px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        font-weight: 400;
      }
      .collectBtn { 
        background: #125DB2;
        color: #ffffff;
      }
      .goWebBtn {
        a {
          color: #909399;
        }
        margin-left: 12px;
        background: #ffffff;
        border: 1px solid #dcdfe6;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .left {
      width: 904px;
    }
    .right {
      width: 270px;
      .companyMember {
        margin-top: 20px;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>